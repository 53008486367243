import React from "react";

import "../style/Project.css";

import project1 from "../img/project-1.png";
import project2 from "../img/project-2.png";
import project3 from "../img/project-3.png";
import project4 from "../img/project-4.png";
import project5 from "../img/project-5.png";
import project6 from "../img/project-6.png";
import projectc1 from "../img/project-c1.png";
import projectc2 from "../img/project-c2.png";
import projectc3 from "../img/project-c3.png";

const Projects = () => {
  return (
    <React.Fragment>
      <header>
        <h1>My Personal Projects</h1>
      </header>
      <section>
        <h2>Software production</h2>
        <div className="project">
          <img className="projectImg" src={project1} alt="Plant GUI" />
          <div>
            <p>
              <strong>pH Plant Graphical User Interface</strong>
            </p>
            <p>
              Part of my master's program at Unicamp, programmed with Python and
              Qt taking in consideration multiple usage environments, such as
              Python 2, Python 3, Qt4 and Qt5. Uses pyqtgraph for plotting, and
              OpenOPC or a custom REST API for communication.
            </p>
          </div>
        </div>
        <div className={`project projectImgRight`}>
          <div>
            <p>
              <strong>DirectShow OpenAL renderer</strong>
            </p>
            <p>
              Experiment to learn more about Windows COM design and OpenAL.
              Programmed in C++, designed with objects since its inception.
            </p>
          </div>
          <img
            className="projectImg"
            src={project2}
            alt="DirectShow OpenAL renderer"
          />
        </div>
        <div className="project">
          <img className="projectImg" src={project3} alt="Basicalc" />
          <div>
            <p>
              <strong>Basicalc</strong>
            </p>
            <p>
              First app I published when learning Windows Phone app development.
              Learned how to use libraries in Visual Studio, publish an app, and
              monetize with DLC. Programmed in C# and WPF.
            </p>
          </div>
        </div>
        <div className={`project projectImgRight`}>
          <div>
            <p>
              <strong>OpenAL Media Player</strong>
            </p>
            <p>
              Pet project to create a music player focused on OpenAL for sound
              output, in addition to supporting sound effects. Programmed in C#
              and WPF.
            </p>
          </div>
          <img
            className="projectImg"
            src={project4}
            alt="OpenAL Media Player"
          />
        </div>
        <div className="project">
          <img
            className="projectImg"
            src={project5}
            alt="OpenAL for Half-Life"
          />
          <div>
            <p>
              <strong>OpenAL for Half-Life</strong>
            </p>
            <p>
              Fork of a client side plugin for the GoldSrc engine (famous
              through Half-Life and Counter-Strike) that adds OpenAL support to
              the engine. Sound back-end rewritten for better 3D positional
              sound, source management, and occlusion support. Programmed in
              C++.
            </p>
          </div>
        </div>
        <div className={`project projectImgRight`}>
          <div>
            <p>
              <strong>Educative Oven GUI</strong>
            </p>
            <p>
              Final project for my undergraduate thesis to help new chemical
              engineering pupils better understand how a simple control system
              behaves. Programmed in Python and Qt to be used with a Raspberry
              Pi. The oven control element was also built and soldered.
            </p>
          </div>
          <img className="projectImg" src={project6} alt="Educative Oven GUI" />
        </div>

        <h2>Software contribution</h2>
        <div className="project">
          <img className="projectImg" src={projectc1} alt="OPC REST API" />
          <div>
            <p>
              <strong>OPC REST API</strong>
            </p>
            <p>
              I contributed to the project while programming the Graphical User
              Interface I developed in my master's. Added a function to write
              variables to a Programmable Logic Controller (PLC) through a
              simple REST API, giving easy access to its variables from almost
              any programming language and toolkit. Programmed in Python.
            </p>
          </div>
        </div>
        <div className="project  projectImgRight">
          <div>
            <p>
              <strong>CSCore</strong>
            </p>
            <p>
              Simple contribution to fix a high output latency bug when using
              OpenAL as audio output backend. Programmed in C#.
            </p>
          </div>
          <img className="projectImg" src={projectc2} alt="CSCore" />
        </div>
        <div className="project">
          <img className="projectImg" src={projectc3} alt="Dolphin" />
          <div>
            <p>
              <strong>Dolphin</strong>
            </p>
            <p>
              Various contributions related to audio, some still on pending Pull
              Requests. Mostly importantly the addition of a free surround sound
              decoder, allowing games to be easily played on common surround
              speakers with surround sound for a more immersive experience.
            </p>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Projects;
