import React from "react";

import "../style/Contact.css";

const Contact = () => {
  return (
    <React.Fragment>
      <form name="contact" netlify netlify-honeypot="bot-field" hidden>
        <input type="text" name="name" />
        <input type="email" name="email" />
        <textarea name="comment"></textarea>
      </form>
      <header>
        <h1>Contact me</h1>
      </header>
      <section>
        <p>Want to stay in touch? Just send me a message and I will answer.</p>
        <form name="contact" method="post">
          <input type="hidden" name="form-name" value="contact" />
          <div>
            <label for="name">Name *</label>
            <input type="text" id="name" name="name" minlength="5" required />
          </div>
          <div>
            <label for="email">Email *</label>
            <input
              type="email"
              class="form-control"
              name="email"
              id="email"
              required
            />
          </div>
          <div>
            <label for="comment">Comment *</label>
            <textarea
              name="comment"
              class="form-control"
              id="comment"
              cols="15"
              rows="4"
              required
            ></textarea>
          </div>

          <div>
            <button type="submit" class="btn">
              SUBMIT
            </button>
          </div>
        </form>
      </section>
    </React.Fragment>
  );
};

export default Contact;
