import React from "react";

const Intro = () => {
  return (
    <React.Fragment>
      <header>
        <h1>Hi, there! I'm Felipe Safady.</h1>
        <h2>Technical Leader, Programmer, Developer.</h2>
      </header>
      <section>
        <p>I'm an developer and engineer currently working for Signiant.</p>
        <p>
          Here you can get to know me a little bit more, and find some of my
          previous works, some created on my spare time and others for my
          research projects.
        </p>
      </section>
    </React.Fragment>
  );
};

export default Intro;
