import React from "react";

import "../style/Skills.css";

const Skills = () => {
  return (
    <React.Fragment>
      <header>
        <h1>Skills</h1>
      </header>
      <section className="skillsContainer">
        <div className="card">
          <h3>Programming</h3>
          <span>Java, C#, C++, Pyton, C, SQL, VBA, JavaScript.</span>
        </div>
        <div className="card">
          <h3>Frameworks & tools</h3>
          <span>
            SpringBoot, IntelliJ, OpenAL, .NET, Git, Microsoft Visual Studio,
            SciLab, Qt, SQLite, WPF.
          </span>
        </div>
        <div className="card">
          <h3>Other</h3>
          <span>Linux, Windows, Windows Phone Development, Agile, CI, VSTS.</span>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Skills;
