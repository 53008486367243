import React, { useState } from "react";

import "../style/App.css";
import Intro from "./Intro";
import About from "./About";
import Projects from "./Projects";
import Skills from "./Skills";
import Contact from "./Contact";

const App = () => {
  const [option, setOption] = useState("intro");

  const handleNavClick = (selected) => {
    setOption(selected);
  };

  return (
    <React.Fragment>
      <header className="header">Felipe Safady - Software Developer</header>

      <body>
        <div className="container">
          <nav className="left">
            <ul>
              <li onClick={() => handleNavClick("intro")}>Intro</li>
              <li onClick={() => handleNavClick("about")}>About me</li>
              <li onClick={() => handleNavClick("projects")}>Projects</li>
              <li onClick={() => handleNavClick("skills")}>Skills</li>
              <li onClick={() => handleNavClick("contact")}>Contact me</li>
            </ul>
          </nav>

          <article className="main">
            {option === "intro" && <Intro />}
            {option === "about" && <About />}
            {option === "projects" && <Projects />}
            {option === "skills" && <Skills />}
            {option === "contact" && <Contact />}
          </article>
        </div>
      </body>

      <footer></footer>
    </React.Fragment>
  );
};

export default App;
