import React from "react";

const About = () => {
  return (
    <React.Fragment>
      <header>
        <h1>About me</h1>
      </header>
      <section>
        <p>
          At work I am a technical leader responsible for integrations and
          general improvements, such as non-blocking code and better threading
          with less locking. There I am in constant contact with various
          technologies such as Java, SQS, AWS and PostgreSQL. As a technical
          leader I am also responsible for our deliverables and their quality.
        </p>
        <p>
          Besides work, I've created and contributed to FOSS projects, mainly
          using C++, C#, Python and Qt, but I also have experience with WPF,
          Windows Phone development, SQLite, SciLab, Microsoft Office, and
          LibreOffice. Some of my programming interest include graphical
          interfaces, audio (OpenAL), 3D graphics (OpenGL), media (DirectShow),
          games, among others. I am well versed in both Linux and Windows
          development, and I am currently investigating Rust and functional
          programming.
        </p>
        <p>
          I graduated in chemical engineering at Federal University of São
          Carlos (UFSCar) in Brazil and in 2018 I finished my masters at
          University of Campinas (Unicamp) in chemical engineering, which I
          focused in automation and process control of a pH reactor using an
          uncommon technique in the scope of chemical engineering. I had my
          first contact with computers when I was just 9 when my parents had to
          buy one for work. Since then computing and engineering became my
          passion. At first a hobby, but now a career.
        </p>
        <p>
          When I am not programming or studying I am enjoying my wife's company,
          watching movie, photographing and listening to music or even creating
          some (at least trying, hahaha).
        </p>
        <p>
          My GitHub account with my FOSS projects and contributions to other
          projects:{" "}
          <a href="https://github.com/LAGonauta">
            https://github.com/LAGonauta
          </a>
        </p>
      </section>
    </React.Fragment>
  );
};

export default About;
